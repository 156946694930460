@import '../../../styles/shared';

.select-col-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 49%;
  width: 100%;

  .select-wrapper {
    @include display-flex;
    align-items: center;
    cursor: pointer;

    &.select-error {
      border: 1px solid $error-color;
    }

    .select-name {
      color: $dark-grey-blue;
    }

    .select-required {
      color: $required-red;
    }

    .placeholder {
      position: absolute;
      pointer-events: none;
      top: 15px;
      bottom: 0;
      //left: 15px;
      margin: auto 15px;
      color: $dark-grey-blue;
  
      span {
        color: $required-red;
      }
    }

    [class*="icon"] {
      color: $primary-black;
      font-size: inherit;
      padding: 15px 7px 15px 12px;
      vertical-align: middle;
      width: 17px;

      path {
        fill: $primary-black;
      }
    }

    &.has-icon {
      .placeholder {
        left: 37px;
        margin: auto;
      }

      input {
        &[type="email"],
        &[type="password"],
        &[type="text"] {
          padding-left: 5px;
        }
      }
    }

    @media screen and (max-width: 414px) {
      .icon {
        font-size: 1rem;
        right: 23px;
        position: absolute;
      }
    }
  }

  .select-div {
    background: #F5F6FA 0% 0% no-repeat padding-box;
    font-size: 1rem;;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 5px;
    display:flex;
    position: relative;
    max-width: 100%;
    width: 100%;

    [class*="icon"] {
      color: $primary-black;
      font-size: inherit;
      padding: 15px 7px 15px 12px;
      vertical-align: middle;
      width: 17px;

      path {
        fill: $primary-black;
      }
    }

    [class*="error-icon"] {
      path {
        fill: $error-color;
      }
    }

    [class*="success-icon"] {
      color: #fff;

      path {
        fill: $green;
      }
    }

    &.input-error {
      border: 1px solid $error-color;
    }

    @media all and (max-width: 480px) {
      max-width: 100%;
    }
  }

  input {
    font-size: 1rem;;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 30px #f5f6fa inset !important;
      box-shadow: 0 0 0 30px #f5f6fa inset !important;
    }

    &[type="email"],
    &[type="password"],
    &[type="text"] {
      background: #f5f6fa;
      border: 0;
      outline: 0;
      padding: 15px 12px 15px 15px;
      width: 100%;
    }

    &:not([value=""]) + .placeholder {
      display:none;
    }
  }

  .select-options {
    background: #fff;
    color: $primary-black;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    display: none;
    position: absolute;
    max-height: 50vh;
    overflow: auto;
    z-index: 2;
    width: 100%;

    > div {
      cursor: pointer;
      text-align: left;
      padding: 9px 21px;

      &:first-of-type {
        cursor: default;
        color: $dark-grey-blue;
      }

      &:not(:first-of-type):hover,
      &:not(:first-of-type):focus {
        background-color: $light-grey-blue;
      }
    }
  }

  span {
    padding-left: 5px;
  }

  @media all and (max-width: 480px) {
    max-width: 100%;
  }
}
