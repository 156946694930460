@import '../../styles/shared';


.forgot-password-wrapper {
  margin-bottom: 5.4%;

  div,
  p,
  input {
    font-size: 1rem;
  }

  a {
    color: #11232e;
    font-weight: 700;
    text-decoration: none;
  }

  .form-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 64px 64px 100px;
    border-radius: 3px;
    box-shadow: 0px 0px 25px #0000000D;
    margin: auto;
    max-width: 375px;

    .input-col-wrapper {
      max-width: 100%;
    }

    .forgot-password-msg {
      color: $dark-grey-blue;
      margin-bottom: 20px;
    }

    @media all and (max-width: 768px) {
      padding: 40px 20px;
      max-width: 375px;
    }

    @media all and (max-width: 480px) {
      padding: 29px 11px;
      max-width: 300px;
    }
  }

  .login-link {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;

    a {
      font-weight: bold;
    }
  }
}