@import '../../../styles/shared';

.calendar-wrapper {
  position: relative;
  width: 100%;

  .custom-selected {
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .calendar-input {
    padding: 0 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-h {
    height: 375px;
  }

  .calendar-range {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: -1px;
    padding: 16px 0;
    background-color: $white;
    border: 1px solid rgba($grey, 0.3);

    .calendar {
      border: 1px solid rgba($grey, 0.3);
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      padding-bottom: 15px;
      height: 350px;

      &:first-child {
        margin-right: 10px;
      }

      .date-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        height: 40px;
        font-size: 15px;
        color: $text-grey;
        background-color: $background-grey;
        border-bottom: 1px solid $medium-grey-blue;
      }

      .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;

        .month {
          font-size: 24px;
          margin: 0;
        }

        button {
          background-color: transparent;
          border: none;
          cursor: pointer;

          svg {
            width: 16px;
            height: 16px;

            &.left,
            &.right {
              path {
                fill: $dark-grey;
              }
            }

            &.left {
              margin-left: 25px;
              transform: rotate(180deg);
            }

            &.right {
              margin-right: 25px;
            }
          }
        }
      }

      .calendar-content {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;

        div {
          padding: 7px;
          margin: 0 auto;
          font-weight: bold;

          &.cursor-pointer {
            cursor: pointer;
          }

          &.day-of-week {
            font-size: 10px;
            color: $grey;
          }

          &.date {
            padding-bottom: 0;
          }
        }

        .dots {
          padding: 0;

          .dot {
            padding: 0;
            height: 7px;
          }
        }
      }

      .prev, .next {
        color: $dark-grey;
        opacity: 0.5;
      }

      .selected {
        color: $white;
        background-color: $primary;
        border-radius: 5px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .calendar-range {
      flex-direction: column;

      .calendar {
          &:first-child {
            margin-right: 0;
          }
        .calendar-content {
          div {
            padding: 6px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .calendar-range {

      .calendar {
        &:first-child {
          margin-right: 0;
        }
        .calendar-content {
          div {
            padding: 6px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 568px) {
    .calendar-range {
      flex-direction: column;
      .calendar {
        &:first-child {
          margin-right: 0;
        }
        .calendar-content {
          div {
            padding: 5px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .calendar-range {
      .calendar {
        &:first-child {
          margin-right: 0;
        }
        .calendar-content {
          div {
            padding: 6px;
          }
        }
      }
    }
  }

}


