@import '../../../styles/shared';

.btn {
  cursor: pointer;
  background: $primary-blue 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  outline: 0;
  color: #ffffff;
  font-size: 1.286rem;
  font-weight: bold;
  height: 45px;
  margin: 16px auto;
  padding: 9px 12px 12px;
  max-width: 218px;
  width: 100%;

    &.small {
    max-width: 110px;
    height: 35px;
    padding: 0px;
    margin-right: 0px;
    background: #00A0E9 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 14px/17px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  &.large {
    max-width: 374px;

    @media screen and (max-width: 480px) {
      max-width: 330px;
    }
  }

  &:hover:not(:disabled) {
    text-decoration: none;
    font-weight: 700;
  }

  &:focus {
    outline: 0;
    font-weight: 700;
  }

  &:disabled {
    cursor: default;
    opacity: 0.8;
  }

  &.loading-icon {
    margin-right: 7px;
    top: 2px;
    //animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }
}