@import '../../../styles/shared';

.input-col-wrapper {
  margin-bottom: 16px;
  position: relative;
  max-width: 49%;
  width: 100%;

  .required {
    color: $error-color;
  }

  .placeholder {
    position: absolute;
    pointer-events: none;
    top: 15px;
    bottom: 0;
    left: 15px;
    margin: auto;
    color: $dark-grey-blue;

    span {
      color: $required-red;
    }
  }

  .input-box {
    background: #F5F6FA 0% 0% no-repeat padding-box;
    font-size: 1rem;;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 5px;
    display:flex;
    //margin-bottom: 16px;
    position: relative;
    max-width: 100%;//49%;
    width: 100%;

    [class*="icon"] {
      color: #768092;
      font-size: inherit;
      padding: 15px 7px 15px 12px;
      vertical-align: middle;
      width: 17px;
      height: 17px;

      path.a {
        fill: #768092;
      }
    }

    [class*="error-icon"] {
      path.a {
        fill: $error-color;
      }
    }

    [class*="success-icon"] {
      color: #fff;

      path.a {
        fill: $green;
      }
    }

    &.input-error {
      border: 1px solid $error-color;
    }

    @media all and (max-width: 480px) {
      max-width: 100%;
    }
  }

  input {
    font-size: 1rem;;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 30px #f5f6fa inset !important;
      box-shadow: 0 0 0 30px #f5f6fa inset !important;
    }

    &[type="email"],
    &[type="password"],
    &[type="text"] {
      background: #f5f6fa;
      border: 0;
      outline: 0;
      padding: 15px 12px 15px 15px;
      width: 100%;
    }

    &:not([value=""]) + .placeholder {
      display:none;
    }
  }

  .sub-label {
    color: $dark-grey-blue;
    font-size: 0.85rem;
    text-align: left;
    margin-top: 5px;
  }

  &.has-icon {
    .placeholder {
      left: 37px;
      margin: auto;
    }

    input {
      &[type="email"],
      &[type="password"],
      &[type="text"] {
        padding-left: 5px;
      }
    }
    
  }

  @media all and (max-width: 480px) {
    max-width: 100%;
  }
}