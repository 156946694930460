@import "../../../styles/shared";

.banner {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  opacity: 1;
  z-index: 999;

  > div {
    height: 69px;

    @media screen and (max-width: 760px) {
      height: 50px;
    }
  }

  .u-banner {
    .banner-r {
      display: flex;
      justify-content: flex-end;

      .language-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-right: 12px;

        .lang-namme {
          padding: 0 10px;
        }

        [class*="icon"] {
          path {
            fill: $primary-black;
          }
        }
      }

      .language-options {
        top: 37px;
        right: 20px;

        &:after {
          right: 30px;
          left: unset;
        }
      }
    }
  }

  .a-banner {
    background: #11232e 0% 0% no-repeat padding-box;
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100.14%;
    //padding: 5px;

    [class*="icon"] {
      .a {
        fill: #fff;
      }
    }

    a,
    p,
    div {
      text-decoration: none;
    }

    .banner-r {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 40%;
      width: 100%;

      .language-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;

        .lang-name {
          padding: 0 10px;
        }
      }

      > div {
        cursor: pointer;
        padding: 10px;

        &:not(:last-of-type) {
          margin-right: 4%;

          @media screen and (max-width: 850px) {
            margin-right: 2%;
          }
        }

        &.banner-profile-wrapper {
          display: flex;
          align-items: center;
          position: relative;
          flex-direction: row;
          justify-content: space-between;
          border-radius: 50%;
          max-width: 180px;

          .avatar-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 10px;
            span {
              padding: 0 5px;
            }
          }

          .avatar-image-small {
            border-radius: 50%;
            height: 39px;
            width: 39px;
            object-fit: cover;
            object-position: center top;
            display: block;
          }
          .avatar-initials-small {
            @include display-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 39px;
            width: 39px;
            object-fit: cover;
            object-position: center top;
            background: #758091;
            text-align: center;
            position: relative;

            span {
              font-size: 16px;
              font-weight: bold;
            }
          }

          .capitalize {
            text-transform: capitalize;
          }

          .icon {
            margin-left: 5px;
          }
        }

        &.banner-contact-wrapper {
          .icon {
            border: 1px solid #fff;
            border-radius: 50%;
            text-align: center;
            line-height: 15px;
            vertical-align: middle;
            padding: 4px;
            height: 15px;
            width: 15px;

            path {
              fill: $primary-blue;
            }
          }
        }

        @media screen and (max-width: 850px) {
          padding: 5px;
        }

        @media screen and (max-width: 760px) {
          &:not(.banner-notification-wrapper) {
            display: none !important;
          }

          &.banner-notification-wrapper {
            position: absolute;
            right: 72px;
            margin: 0;
          }
        }
      }

      @media screen and (max-width: 760px) {
        display: none;
      }
    }

    .banner-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 60%;
      width: 100%;

      > .top-nav {
        display: flex;
        align-items: center;
        margin-right: 4%;

        a {
          padding-left: 18px;
        }

        > div {
          @include display-flex;
          cursor: pointer;
          align-items: center;
          padding: 10px 7px 10px 21px;

          @media screen and (max-width: 850px) {
            padding: 5px;
          }

          @media screen and (max-width: 760px) {
            position: absolute;
            right: 17px;
            margin: 0;
            height: 100%;
            max-height: 30px;
          }
        }

        .company-logo {
          cursor: pointer;
          width: auto;

          @media screen and (max-width: 850px) {
            padding: 10px;
          }
        }

        @media screen and (max-width: 850px) {
          margin-right: 2%;
        }
      }

      .banner-search-container {
        padding: 10px;

        .search {
          // border: 1px solid #424348;
          // border-radius: 25px;
          // flex: 1 1 0;
          // background: transparent;
          // color: #ffffff;
          // display:flex;
          // //padding: 2px;
          // max-height: 40px;
          // max-width: 642px;
          width: 40vw;

          // .search-icon {
            //color: #00a0e9;
            // padding: 9px 5px 9px 12px;
            // vertical-align: middle;
          // }

          input {
            background: transparent;
            color: #ffffff;
            // font-size: inherit;
            // border: 0;
            // outline: 0;
            // padding: 0 6px;
            max-width: 35vw;
            // width: 100%;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active,
            &:-internal-autofill-selected {
              -webkit-box-shadow: 0 0 0 30px #f5f6fa inset !important;
              box-shadow: 0 0 0 30px #f5f6fa inset !important;
            }

            &::-webkit-input-placeholder {
              /* Edge */
              color: #fff;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #fff;
            }

            &::placeholder {
              color: #fff;
            }
          }

          @media screen and (max-width: 1024px) {
            width: 30vw;
          }
        }

        @media screen and (max-width: 760px) {
          display: none !important;
        }
      }
    }
  }

  .language-container {
    .language-options {
      background: #fff;
      color: #000;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 3px;
      padding: 13px 23px;
      max-width: 65px;
      position: absolute;
      top: 69px;

      > div {
        cursor: pointer;
        color: $primary-black;
      }

      &:after {
        content: " ";
        position: absolute;
        left: 30px;
        top: -4px;
        border-top: none;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        border-bottom: 6px solid #fff;
      }
    }
  }

  &.u-header {
    position: relative;
  }
}
