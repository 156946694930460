@import '../../../styles/shared';

.menu-wrapper {
  background: #11232E;
  color: #fff;
  font-size: 1.175rem;
  height: 100%;
  position: fixed;
  top: 68px; //69px;
  left: 0;
  overflow-x: hidden;
  transition: width 300ms linear 0s;
  max-width: 263px;
  width: 61px;
  z-index: 12;

  [class*="icon"] {
    .a {
      color: #fff;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-size: 1rem;// 1.34rem;

      a {
        color: #fff;
        font-size: 1rem; //1.34rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 23px 21px;

        &:hover,
        &.active {
          background: #183343;
        }

        &.active {
          font-weight: bold;
          .icon path{
            fill: $primary-blue;
          }
        }

        > div {
          width: 47px;
        }

        .icon {
          margin-right: 28px;
        }

        .arrow-right,
        .arrow-down {
          font-size: 0.9rem;
          margin: 0;
          position: absolute;
          right: 23px;
        }

        @media screen and (max-width: 414px) {
          font-size: 1.34rem;
        }
      }
    }
  }

  .hr {
    background: #11232E 0% 0% no-repeat padding-box;
    opacity: 0.1;
  }
  .banner-profile-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    .handle-open {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
    }
    .avatar-right {
      display: flex;
      justify-content: space-between;
    }
    .avatar-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .avatar-image-small {
      border-radius: 50%;
      height: 39px;
      width: 39px;
      object-fit: cover;
      object-position: center top;
      display: inline-flex;
      margin-right: 5px;
    }
    .avatar-initials-small {
        @include display-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 39px;
        width: 39px;
        object-fit: cover;
        object-position: center top;
        background: #758091;
        position: relative;

          span {
            font-size: 16px;
            font-weight: bold;
          }
      }
    .profile-name {
      display: inline-flex;
      align-items: center;
      margin:0px 5px;
    }
    .capitalize {
      text-transform: capitalize;
      padding-left: 5px;
    }
  }

  .menu-item:not(.menu-language-wrapper) {
    > div {
      padding: 21px 23px;
    }

    &.menu-button-wrapper {
      > div {
        padding: 10px 23px;
      }

      @media screen and (min-width: 760px) {
        display: none !important;
      }
    }

    .btn {
      margin:0;
      border-radius: 3px;
    }
  }

  .menu-search-container {
    .search {
      position: relative;

      [class*="icon"] {
        position: absolute;
        right: 20px;
        top: 2px;
      }

      input {
        background: transparent;
        color: #ffffff;
        padding-left: 20px;
      
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active,
        &:-internal-autofill-selected {
          -webkit-box-shadow: 0 0 0 30px #f5f6fa inset !important;
          box-shadow: 0 0 0 30px #f5f6fa inset !important;
        }

        &::-webkit-input-placeholder { /* Edge */
          color: #fff;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #fff;
        }

        &::placeholder {
          color: #fff;
        }
      }
    }

    @media screen and (min-width: 760px) {
      display: none !important;
    }
  }

  .language-container {
    padding: 0;

    .language-wrapper {
      padding: 21px 23px; //21px 23px 21px 65px;
    }

    .language-options {
      background: #fff;
      color: #11232E;
      padding: 21px 23px;
    }

    @media screen and (min-width: 760px) {
      display: none !important;
    }
  }

  &.nav-expand {
    width: 263px;

    @media screen and (max-width: 760px) {
      width: 100vw;
    }
  }

  @media screen and (max-width: 760px) {
    //position: absolute;
    top: 50px;
    max-width: 100%;
    width: 0;
  }

  // @media screen and (max-width: 414px) {
  //   top: 0;
  // }
}