@import '../styles/variables';
@import '../styles/common';

#main-content {
  background-color: $white;
}
@import '../styles/shared';

#page-content {
  display: inline-block;
  min-height: 95vh;
  top: 69px;
  margin-bottom: 60px;
  position: relative;
  width: 100%;

  #main {
    padding: 10px 25px;
    margin-left: 61px;
    position: relative;
    max-width: 100%;
    transition: margin-left .3s;

    > div.public-page {
      margin-left: unset;
    }

    @media screen and (max-width: 760px) {
      margin-left: 0;
      padding: 0;
    }
    @media screen and (max-width: 414px) {
      padding: 0;
      top: 50px;
    }
  }

  @media screen and (max-width: 760px) {
    top: 0;
  }
}

.center {
  text-align: center
}

.error-message {
  font-size: 0.85rem !important;
  color: $error-color;
  margin-top: 5px;
  text-align: left;
}

.success-message {
  font-size: 0.85rem !important;
  color: $success-color;
  margin-top: 5px;
  text-align: left;
}

[class*="icon"] {
  font-size: 1.4rem;
}

.hr {
  background-color: $medium-grey-blue; //#11232E;
  border: none;
  border-top: 1px solid #d7d0d0;
}

.d-flex {
  @include display-flex;
}

.flex-row {
  @include flex-direction-row;
}

.flex-column {
  @include flex-direction-column;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-evenly {
  justify-content: space-evenly;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.Toastify {
  .Toastify__toast-container {
    top: 0;
    margin: 0;
    padding: 0;
    left: 0;
    transform: unset;
    width: 100vw;

    .Toastify__toast {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      min-height: 80px;

      .Toastify__toast-body {
        color: $dark-grey-blue;
        font-weight: bold;
        text-align: center;
      }

      .Toastify__close-button  {
        color: $dark-grey-blue;
      }

      .Toastify__progress-bar  {
        background: $medium-grey-blue;
        height: 3px;
      }

      &.Toastify__toast--error {
        background: #FFF6F6;
        border: 1px solid #973937;

        .Toastify__toast-body,
        .Toastify__close-button  {
          color: #973937;
        }

        .Toastify__progress-bar  {
          background: #cdacac;
        }
      }

      &.Toastify__toast--success {
        background: #FCFFF5;
        border: 1px solid #A8C599;

        .Toastify__toast-body,
        .Toastify__close-button {
          color: #1E561F;
        }

        .Toastify__progress-bar  {
          background: #A8C599;
        }
      }

      &.Toastify__toast--warning {
        background: #FFFAF3;
        border: 1px solid #CCBEA0;

        .Toastify__toast-body,
        .Toastify__close-button {
          color: #7A4D05;
        }

        .Toastify__progress-bar  {
          background: #CCBEA0;
        }
      }

      &.Toastify__toast--info {
        background: #F8FFFF;
        border: 1px solid #BEDFE6;

        .Toastify__toast-body,
        .Toastify__close-button {
          color: #0E566C;
        }

        .Toastify__progress-bar  {
          background: #BEDFE6;
        }
      }
    }


  }
}

.loading-icon {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  @include display-flex;
  justify-content: center;
  position: fixed;
  top:0;
  align-items: center;
  height: 100vh;
  margin: auto;
  width: 100vw;

  .loading-icon {
    font-size: 3rem;
    animation: spin infinite 3s linear;
  }
}
