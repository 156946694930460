@import '../../styles/shared';
$char-w: 2.70ch; //2.65ch;
$space: .09*$char-w; //.5*$char-w;
$no-char: 6; //4;
$w: $no-char*($char-w + $space);

.confirmation-code-container {
  margin-bottom: 5.4%;

  div,
  p,
  input {
    font-size: 1rem;
  }

  a {
    color: #11232e;
    font-weight: 700;
    text-decoration: none;
  }

  .success-message,
  .error-message {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .form-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 64px 64px 100px;
    border-radius: 3px;
    box-shadow: 0px 0px 25px #0000000D;
    margin: auto;
    max-width: 375px;
    //width: 100%;



    .confirm-code {
      .resend-link {
        cursor: pointer;
        font-weight: 700;
        text-decoration: underline;
      }

      .input-fields {
        @include display-flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 3rem;

        .input-box {
          width: 100%;
        }

        input {
          border: none;
          width: $w;
          background: 
            repeating-linear-gradient(90deg, 
                $medium-grey-blue 0, 
                $medium-grey-blue $char-w,
                transparent 0, 
                transparent $char-w + $space) 
              0 100%/#{$w - $space} 2px no-repeat;
          color: $dark-grey-blue;
          font-size: 4.5ch;
          letter-spacing: $space+1.675;
          padding-left: 1rem;//1.4rem;
        }

        input:focus {
          outline: none;
          //color: ;
          //color: $medium-grey-blue;
        }
  
        ::placeholder {
          color: $medium-grey-blue;;
          opacity: 1; /* Firefox */
        }
        
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: $medium-grey-blue;;
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
         color: $medium-grey-blue;;
        }

  
        @media all and (max-width: 480px) {
          flex-direction: column;
          flex: 1;
        }
      }

      
  
      // .input-box {
      //   background: #F5F6FA 0% 0% no-repeat padding-box;
      //   font-size: 1rem;;
      //   box-shadow: 0px 1px 1px #00000029;
      //   border-radius: 5px;
      //   display:flex;
      //   margin-bottom: 16px;
      //   position: relative;
      //   max-width: 49%;
      //   width: 100%;
        
      //   [class*="icon"] {
      //     color: #768092;
      //     font-size: inherit;
      //     padding: 15px 7px 15px 12px;
      //     vertical-align: middle;
      //   }
  
      //   @media all and (max-width: 480px) {
      //     max-width: 100%;
      //   }
      // }
  
      // input {
      //   font-size: 1rem;;
      //   &:-webkit-autofill,
      //   &:-webkit-autofill:hover, 
      //   &:-webkit-autofill:focus, 
      //   &:-webkit-autofill:active,
      //   &:-internal-autofill-selected {
      //     -webkit-box-shadow: 0 0 0 30px #f5f6fa inset !important;
      //     box-shadow: 0 0 0 30px #f5f6fa inset !important;
      //   }
  
      //   &[type="email"],
      //   &[type="password"],
      //   &[type="text"] {
      //     background: #f5f6fa;
      //     border: 0;
      //     outline: 0;
      //     padding: 15px 12px 15px 5px;
      //     width: 100%;
      //   }
      // }
    }

    @media all and (max-width: 768px) {
      padding: 40px 20px;
      max-width: 375px;
    }

    @media all and (max-width: 480px) {
      padding: 29px 11px;
      max-width: 300px;
    }
  }
}