@import '../../../styles/shared';

.loading-spinner {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 0.5rem solid rgba(117, 128, 145, 0.3);
  border-left: 0.5rem solid $primary-black;
  border-radius: 50%;
  animation: spinning 1.1s infinite linear;
  z-index: 9;

  @keyframes spinning {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

#main {
  .loading-spinner {
    position: relative;
    margin-top: 15%;
    width: 3em;
    height: 3em;
    border-width: 0.3rem;
    border-left-width: 0.3rem;
  }
}

