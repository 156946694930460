@import '../../styles/shared';

//avatar
.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 20px;

  label {
    position: relative;
  }
}
.avatar-image,
.avatar-image-initials {
  border-radius: 50%;
  height: 114px;
  width: 114px;
  object-fit: cover;
  object-position: center top;
  display: block;
}
.avatar-image-initials {
  background: #758091;
  position: relative;
  span {
    position: absolute;
    left: 30%;
    top: 32%;
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;
  }
}
.img-div {
  position: relative;
}
.cam-icn {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.avatar-container .person-name {
  margin-top: 10px;
  font-weight: bold;
}
.file-input {
  display: none;
}

//Details
.details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.details-label {
  color: #11232e;
  opacity: 0.4;
}

//EditPassword
.edit-password-container {
  display: flex;
  flex-direction: column;
  border: 0px;
  border-bottom: 1px;
  border-style: solid;
  border-color: $medium-grey-blue;
  padding: 20px;
}

//EditProfile
.edit-profile-container {
  display: flex;
  flex-direction: column;
  border: 0px;
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  border-color: $medium-grey-blue;
  padding: 20px;
}

.edit-profile-form-container {
  display: flex;
  flex-direction: column;
}

.edit-profile-form-container,
.edit-password-container,
.office-info-container {
  .input-col-wrapper {
    max-width: 100% !important;
      &:first-child {
        margin-top: 20px;
      }
  }
  .select-col-wrapper {
    max-width: 100% !important;
    margin-bottom: 16px !important;
    .select-wrapper {
      background: #F5F6FA 0% 0% no-repeat padding-box;
      font-size: 1rem;;
      box-shadow: 0px 1px 1px #00000029;
      border-radius: 5px;
    }
    .select-options {
      height: 200px;
      overflow: scroll;
    }
  }
}

.logo {
  margin-right: 15px;
}


//Office-Info
.office-info-container {
  display: flex;
  flex-direction: column;
  border: 0px;
  border-bottom: 1px;
  border-style: solid;
  border-color: $medium-grey-blue;
  padding: 20px;
}
//Index
.profile-container {
  position: fixed;
  top: 69px;
  right: 0px;
  height: calc(100vh - 70px);
  //height: 930px;
  width: 400px;
  box-shadow: -3px 3px 5px #0000000A;
  opacity: 1;
  overflow: scroll;
  color: black;
  background-color: #FFFFFF;
  z-index: 5;

  .profile-input-box {
    @include display-flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #11232E;
    opacity: 1;
    width: 163px;
    height: 40px;
    margin: 30px auto;
    text-align: center;

    .signout-btn {
      // text-align: left;
      // width: 52px;
      // height: 19px;
      // font: normal normal bold 14px/19px Roboto;
      // letter-spacing: 0px;
      color: #11232E;
      opacity: 1;
      cursor: pointer;
      vertical-align: super;
      margin-left: 10px;
      font-weight: bold;
    }
  }
}

.profile-fields{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  background: #F5F6FA 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 5px;
  margin: 6px;
  position: relative;
  width: 500px !important;
  border: 0px;
  height: 40px;
}
.profile-select-fields{
  display: flex;
  flex-direction: column;
  background: #F5F6FA 0% 0% no-repeat padding-box;
  margin: 6px;
  position: relative;
  border: 0px;
  height: 40px;
  padding: 0px !important;
}
.name-icon-profile {
  display: inline-flex;
  position: relative;
  right: 5px;
  justify-content: space-between;
  width: 10;
  width: 100%;
  align-items: center;
}
.edit-profile-details {
  position: relative;
  display: flex;

  .profile-caret {
    path.a {
      fill: $primary-black;
    }
    position: absolute;
    transform: rotate(-90deg);
    right: 0px;
    margin: 10px;
  }
}
.arrow-down-profile {
  path {
    fill: black !important;
  }
}

.profile-span{
  vertical-align: middle;
  margin-right: 20px;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #11232E;
  opacity: 1;
}
.sht-dwn {
  padding-top: 5px;
  height: 21px;
  width: 21px;
}

@media all and (max-width: 760px) {
  .profile-container {
    top: 85px;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
  }
}