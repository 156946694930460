@import '../../styles/shared';

::-webkit-scrollbar {
  width: 0.4125rem;
  height: 0.4375rem;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-grey;
  border: 0 none grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-grey;
}

::-webkit-scrollbar-thumb:active {
  background: $scrollbar-grey;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0 none $scrollbar-grey;
  border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dashboard {
  $font-size-1: 45px;
  $font-size-2: 16px;
  $font-size-3: 14px;
  $font-size-4: 12px;

  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $background-grey;

  .lds-dual-ring {
    display: block;
    z-index: 100;
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    margin: auto;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $primary;
    border-color: $primary transparent $primary transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .congratulations-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    .modal-overlay {
      position: absolute;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
    }

    .csv-modal {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 5;
      margin: auto;
      border: 1px black solid;
      max-width: 655px;
      width: 100%;
      height: 468px;
      background-color: white;
      box-shadow: 0 0 99px 800px $white-blur;

      .calendar-wrapper {
        width: 50%;
        align-self: center;
        border: 1px solid $primary;

        .calendar-range {
          left: -150px;
          width: auto;
        }

        svg {
          path {
            fill: $primary;
          }
        }
      }

      .download-csv {
        align-self: center;
        margin: 50px;

        button {
          width: 300px;
          height: 45px;
          border: none;
          color: $white;
          background-color: $primary;
        }
      }

      .close-button {
        cursor: pointer;
        padding: 11px 11px 0 0;
        align-self: flex-end;
      }

      .title {
        justify-self: baseline;
        font-size: 31px;
        font-weight: bold;
      }
    }

    .modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 5;
      margin: auto;
      border: 1px black solid;
      max-width: 606px;
      width: 100%;
      height: 468px;
      background-color: white;
      box-shadow: 0 0 99px 800px $white-blur;

      .close-button {
        cursor: pointer;
        padding: 11px 11px 0 0;
        align-self: flex-end;
      }

      .promo-code-list {
        justify-self: baseline;
        font-size: 31px;
        font-weight: bold;
      }

      .code-list {
        max-width: 499px;
        width: 100%;
        height: 265px;
        display: grid;
        padding: 54px 49px 19px 58px;
        grid-column-gap: 53px;
        grid-row-gap: 14px;
        overflow: scroll;
        grid-template-columns: repeat(5, 1fr);
      }

      .promo-code-total {
        height: 40px;
        align-self: flex-start;
        margin-left: 44px;

        p {
          margin: 0;
          font-weight: bold;
        }

        span {
          padding: 6px;
          font-size: 31px;
        }
      }
    }

  }

  .container {
    width: 100%;
    height: 100%;

    .container-title {
      max-width: 1416px;
      width: 100%;
      height: 133px;
      background: rgb(7, 103, 160);
      background: linear-gradient(90deg, rgba($grad-1, 1) 0%, rgba($grad-2, 1) 35%);
      margin: 30px 0 0 0;

      .title {
        font-size: $font-size-1;
        color: $white;
        margin: 0 57px;
      }
    }

    .tabs {
      max-width: 1416px;
      width: 100%;
      margin: 81px 0 0 0;

      .left {
        max-width: 670px;
        width: 100%;

        .tab-active {
          height: 45px;
          max-width: 330px;
          width: 100%;
          font-size: $font-size-2;
          font-weight: bold;
          color: $black;
          background-color: $white;
          margin: 0 7px 0 0;
          text-align: center;
        }

        .tab-inactive {
          height: 45px;
          max-width: 330px;
          width: 100%;
          font-size: $font-size-2;
          font-weight: 400;
          color: rgba($black, 0.5);
          background-color: rgba($inactive, 0.07);
          margin: 0 7px 0 0;
          cursor: pointer;
          text-align: center;
        }
      }

      .right {
        max-width: 420px;
        width: 100%;
        padding: 0 17px 15px 0;

        .tabs-button {
          margin-left: 8px;
          max-width: 108px;
          width: 100%;
          height: 28px;
          font-size: $font-size-3;
          background-color: $background-grey;
          border: 1px $grey solid;
          color: $blue-black;
          white-space: nowrap;
        }

        .tabs-button:first-child {
          margin: 0;
        }

        .tabs-search {
          height: 26px;
          margin-left: 8px;
          border: 1px $blue-black solid;

          input {
            font-size: 17px;
            float: left;
            width: 95px;
            border: none;
            background-color: $background-grey;
            padding: 0 0 0 10px;
          }

          input:focus {
            outline: none;
          }

          .lens {
            width: 12.5px;
            height: 12.5px;
            padding-right: 5px;
          }
        }
      }
    }

    .container-body {
      max-width: 1416px;
      width: 100%;
      margin-bottom: 50px;
      background-color: $white;
      .container-heater {

        padding: 41px 68px;

        .label {
          padding-bottom: 14px;
          font-size: $font-size-2;
          color: $black;
        }
        .input-wrapper {
          height: 100%;
          width: 100%;
          input {
            padding: 0 0 0 10px;
            height: 43px;
            max-width: 640px;
            width: 100%;
            border: 1px solid #D9DCEA;
            &:focus {
              outline: none;
            }
            &::placeholder, &-ms-input-placeholder, &::-ms-input-placeholder {
              color: rgba($text-grey, 0.3);
            }
          }
          .submit-button {
            height: 45px;
            border-radius: 4px;
            background-color: $primary-blue;
            color: $white;
            margin-left: 13px;
            max-width: 138px;
            width: 100%;
            border: none;
            cursor: pointer;
          }
        }
        .generated-code {
          margin: 38px 0 0 0;
          h1{
            margin: 0 0 0 13px;
          }
        }

        @media screen and (max-width: 1056px) {
          padding: 41px 24px;
          .label {
            text-align: center;
          }
          .input-wrapper {
            flex-direction: column;
            input {
              width: calc( 100% - 12px);
              max-width: unset;
            }
            .submit-button {
              max-width: unset;
              margin: 27px 0 0 0;
            }
          }
          .generated-code {
            text-align: center;
            flex-direction: column;
          }
        }
      }
      .relative {
        position: relative;
      }

      .error {
        position: absolute;
        font-size: 12px;
        color: red;
        margin: 0;
        bottom: -37px;
        white-space: nowrap;
        right: 320px;
        &.generate {
          bottom: -20px;
          left: 0;
        }
      }

      .form {
        max-width: 871px;
        padding: 75px 57px 0 57px;

        form {

          .input-wrapper {
            margin-bottom: 12px;

            .select-col-wrapper {
              margin: 0;
              max-width: 638px;
              border: $border 1px solid;

              input {
                background-color: $white;
                border: none;
              }
            }

            label {
              font-size: $font-size-2;
              color: $text-grey;
              max-width: 165px;
              width: 100%;
              margin-right: auto;

              span {
                color: $primary;
              }
            }

            .checkbox-container {
              padding: 0;
              max-width: 640px;
              width: 100%;

              .form-checkbox {
                align-self: flex-start;
                height: 15px;
                width: auto;
                margin: 0;
              }
            }


            input, .click-outside-wrapper {
              padding: 0;
              max-width: 638px;
              width: calc(100% - 2px);
              border: $border 1px solid;
              height: 45px;
              text-indent: 18px;

              .default-option {
                text-align: center;
              }

              .height {
                height: 45px;
              }

              .options-wrapper {
                height: 90px;
              }

              .options-wrapper > .option {
                background-color: $white;
                height: 45px;
                padding: 0;
                width: 100%;
                text-indent: 18px;
                display: flex;
                align-items: center;
                z-index: 1;
                border: $border solid;

              }

              .option:first-child {
                border-width: 1px 1px 0 1px;
              }

              .option:last-child {
                border-width: 0 1px 1px 1px;
              }
            }

            input:focus {
              outline: none;
            }

            input[type="textarea"] {
              width: calc(100% - 2px);
              height: 107px;
            }

            input[type="number"] {
              max-width: 638px;
              width: calc(100% - 2px);
              -moz-appearance: textfield;
            }

            .calendar-option {
              max-width: 640px;
              width: 100%;
              height: 45px;
              display: flex;
              justify-content: space-between;

              .click-outside-wrapper {
                .options-wrapper {
                  .option {
                    width: 100%;
                    background-color: $light-grey-blue;
                    font-weight: bold;
                    margin: 1.5px;
                  }
                }
              }

              .calendar-wrapper {
                border: $border 1px solid;
                height: 45px;
              }

              svg {
                path {
                  fill: $primary;
                }
              }
            }
          }

          .d-none {
            display: none;
          }
        }

        .submit-button {
          max-width: 640px;
          width: 100%;
          height: 45px;
          background-color: $grad-2;
          color: $white;
          border: none;
          align-self: flex-end;
          margin-bottom: 46px;
          padding: 0;
        }
      }

      .container-archive {
        width: 100%;
        height: 100%;
        margin: 33px 0 25px 0;
        overflow: scroll;

        table {
          border-spacing: 0;
          margin-bottom: 20px;
          width: 1750px;
          table-layout: auto;

          thead {
            height: 38px;

            tr {
              text-align: left;

              th {
                font-size: $font-size-4;
                font-weight: normal;
                vertical-align: bottom;
                padding: 16px 8px;
              }

              .th-id {
                width: 70px;
              }

              .th-product {
                width: 106px;
              }

              .th-description {
                width: 115px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .th-code {
                width: 76px;
              }

              .th-discount {
                width: 58px;
              }

              .th-redeemed {
                width: 61px;
              }

              .th-redeemed-date {
                width: 145px;
              }

              .th-enabled {
                width: 52px;
              }

              .th-enabled-date {
                width: 84px;
              }

              .th-start,
              .th-end,
              .th-creation {
                width: 160px;
              }

              .th-email {
                width: 120px;
              }

              .th-notes {
                width: 106px;
              }


              th:first-child {
                width: 13px;
                padding: 10px 20px;
                text-align: center;
              }

            }
          }

          tbody {
            tr:nth-child(odd) {
              background-color: $background-grey;
            }

            td {
              padding: 10px;

              &.ellipsis {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.function,
                &.description,
                &.notes {
                  max-width: 130px;
                }

              }
            }

            td:first-child {
              text-align: center;
              padding: 10px 20px;
            }

            .batch {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 65px;

              &:hover {
                overflow: visible;
                z-index: 200;
                position: relative;
                background-color: inherit;
              }

              &.batch:hover span {
                padding-right: 10px;
                background-color: $background-grey;
                border: 1px $grey solid;
              }
            }
          }

          .active {
            width: 50px;
            height: 20px;
            font-size: $font-size-3;
            color: $white;
            background-color: $green;
            border-radius: 10px;
            text-align: center;
            line-height: 20px;
          }

          .inactive {
            width: 50px;
            height: 20px;
            font-size: $font-size-3;
            color: $white;
            background-color: $red;
            border-radius: 10px;
            text-align: center;
            line-height: 20px;
          }
        }
      }

      .card {
        padding: 27px 28px 35px 25px;

        .promo-data {
          max-width: 161px;
          width: 50%;

          div {
            margin-top: 8px;
            margin-bottom: 8px;
          }

          input {
            margin: 0;
            padding: 0;
            height: 14px;
          }

          .table-checkbox {
            height: 14px;
          }

          .toggle {
            margin: 5px 0;
          }

          .batch {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 65px;
            height: 14px;

            &:hover {
              overflow: visible;
              z-index: 200;
              position: relative;
              white-space: nowrap;
              left: -70px;
              top: -30px;
              background-color: inherit;
            }

            &.batch:hover span {
              padding-right: 10px;
              background-color: $background-grey;
            }
          }
        ;

          p:first-child {
            margin: 0;
            height: 20px;
          }
        }

        .active {
          width: 50px;
          height: 20px;
          font-size: $font-size-3;
          color: $white;
          background-color: $green;
          border-radius: 10px;
          text-align: center;
          line-height: 20px;
        }

        .inactive {
          line-height: 20px;
          width: 50px;
          height: 20px;
          font-size: $font-size-3;
          color: $white;
          background-color: $red;
          border-radius: 10px;
          text-align: center;
        }
      }

      .card:nth-child(odd) {
        background-color: $background-grey;
      }
    }

    .button-wrapper {
      margin-left: 165px;

      .button {
        margin: 27px 0 33px 0;
        border: 1px $border-grey solid;
        max-width: 237px;
        width: 100%;
        height: 40px;
        color: $blue-black;
        background-color: $light-grey-blue;
        font-size: $font-size-3;
      }
    }
  }

  .load-more {
    max-width: 240px;
    width: 100%;
    height: 40px;
    font-size: $font-size-3;
    background-color: transparent;
    border: 1px $border-grey solid;
    color: $blue-black;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard {
    $font-size-1: 35px;
    $font-size-2: 16px;
    $font-size-3: 14px;
    $font-size-4: 12px;

    .congratulations-modal {
      .modal {
        max-width: 400px;
        width: 100%;
        margin-left: 25px;
        margin-right: 25px;

        .code-list {
          max-width: 330px;
          width: 100%;
          grid-template-columns: repeat(3, 1fr)
        }
      }
    }

    .container {
      .container-title {
        margin-top: 50px;
        justify-content: center;

        .tile {
          font-size: $font-size-1;
        }
      }

      .tabs {
        margin-top: 30px;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .left {
          .tab-active, .tab-inactive {
            text-align: center;
            font-size: $font-size-4;
            margin: 0;
            padding: 0 19px;
            white-space: nowrap;
          }
        }
      }

      .container-body {
        .container-archive {
          margin: 17px 0 0 0;
          overflow: auto;

          .right {
            padding: 0 28px 0 28px;
            justify-content: space-between;
            flex-wrap: wrap;

            .mr {
              margin-right: 7px;
            }

            .ml {
              margin-left: 7px;
            }

            .tabs-button {
              width: 100%;
              height: 28px;
              font-size: $font-size-3;
              background-color: $background-grey;
              border: 1px $grey solid;
              color: $blue-black;
              flex-grow: 1;
              flex-basis: calc((100% - 21px) / 4);
            }

            .tabs-search {
              height: 38px;
              margin: 17px 0 0 0;
              border: 1px $blue-black solid;
              align-self: center;
              flex-grow: 1;
              flex-basis: calc(100% / 3);
              background-color: $background-grey;
              justify-content: space-between;

              input {
                font-size: 17px;
                float: left;
                max-width: 304.5px;
                width: 100%;
                border: none;
                padding: 0 0 0 10px;
                background-color: $background-grey;
              }

              input:focus {
                outline: none;
              }

              .lens {
                justify-content: flex-end;
                width: 12.5px;
                height: 12.5px;
                padding-right: 5px;
              }
            }
          }
        }
        .form {
          padding: 36px 25px 0 25px;

          form {
            .input-wrapper {
              flex-direction: column;
              align-items: start;

              .error {
                bottom: -15px;
                right: -327px;
              }

              label {
                font-size: $font-size-3;
                padding-bottom: 8px;

              }

              input {
                width: calc(100% - 2px);
              }

              input[type="textarea"] {
                width: calc(100% - 2px);
              }
            }
          }

          .calendar-input-wrapper {
            max-width: 638px;
            width: calc(100% - 2px);

            .calendar {
              width: calc(100% - 2px);
              padding: 20px 0 0 0;

              .calendar-content {
                div {
                  padding: 4px;
                }
              }
            }
          }
        }

        .button-wrapper {
          margin-left: 0;
          justify-content: end;

          .submit-button {
            margin: 0 0 46px 0;
            padding: 0 25px 0 25px;
          }
        }

      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .dashboard {
    .container {
      .container-title {
        margin-top: 0;
      }

      .container-body {
        .container-archive {
          .right {
            .tabs-button {
              flex-basis: calc((100% - 80px) / 4);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .dashboard {
    .container {
      .container-title {
        margin-top: 0;
      }
    }

    .congratulations-modal {
      .modal {
        max-width: 320px;
        width: 100%;

        .code-list {
          margin-left: 25px;
          margin-right: 25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 424px) {
  .dashboard {
    .container {
      .container-title {
        padding-top-top: 50px;
      }
    }
  }
}
