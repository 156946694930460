@import './styles/shared';

html {
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

body {
  background: #F5F6FA 0 0 no-repeat padding-box;

  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
