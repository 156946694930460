@import '../../styles/shared';
$char-w: 2.7ch; //2.7ch;
$space: .09*$char-w; //.3*$char-w;
$no-char: 6;
$w: $no-char*($char-w + $space);

.reset-password-wrapper {
  margin-bottom: 5.4%;

  div,
  p,
  input {
    font-size: 1rem;
  }

  a {
    color: #11232e;
    font-weight: 700;
    text-decoration: none;
  }

  .success-message,
  .error-message {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .form-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 64px 64px 100px;
    border-radius: 3px;
    box-shadow: 0px 0px 25px #0000000D;
    margin: auto;
    max-width: 375px;

    .input-col-wrapper {
      max-width: 100%;
    }

    // &:-webkit-autofill {
    //   -webkit-box-shadow: 0 0 0 1000px white inset !important;
    //   box-shadow: 0 0 0 1000px white inset !important;
    // }

    input[name="confirmationcode"] {
      border: none;
      margin-bottom: 3rem;
      width: $w;
      background: 
        repeating-linear-gradient(90deg, 
            $medium-grey-blue 0, 
            $medium-grey-blue $char-w,
            transparent 0, 
            transparent $char-w + $space) 
          0 100%/#{$w - $space} 2px no-repeat;
      color: $dark-grey-blue;
      font-size: 4.5ch;
      letter-spacing: $space+1.675;
      padding-left: 1rem;

      &:focus {
        outline: none;
      }
    }

    ::placeholder {
      color: $medium-grey-blue;;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $medium-grey-blue;;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $medium-grey-blue;;
    }

    .forgot-password-msg {
      color: $dark-grey-blue;
      margin-bottom: 20px;
    }

    .resend-link {
      cursor: pointer;
      font-weight: 700;
      text-decoration: underline;
    }

    @media all and (max-width: 768px) {
      padding: 40px 20px;
      max-width: 375px;
    }

    @media all and (max-width: 480px) {
      padding: 29px 11px;
      max-width: 300px;
    }
  }
}