@import '../../styles/shared';

.page-not-found {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 64px;
  border-radius: 3px;
  box-shadow: 0px 0px 25px #0000000D;
  margin: 41px auto;
  max-width: 50%;
  width: 100%;

  > div {
    margin-top: 50px;
  }

  a {
    color: $primary;
    font-weight: bold;
    text-decoration: none;
  }
}
