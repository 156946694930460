@import '../../../styles/shared';

.public-page-heading {
  div,
  p,
  input {
    font-size: 1rem;
  }

  a {
    color: #11232e;
    font-weight: 700;
    text-decoration: none;
  }

  .divider {
    border: 2px solid $primary-blue;
    margin: 40px auto;
    max-width: 100px;
  }

  .public-page-intro {
    max-width: 68%;
    margin: auto auto 32px;
  }
}