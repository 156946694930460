$primary: #20A2E8;
$primary-border: #54A0E2;
$white: #ffffff;
$light-grey: #F1F4F5;
$dark-grey: #474950;
$grey: #707070;
$background-grey: #F5F6FA;
$black: #000000;
$image-background: #4E6285;
$navy-blue: #11232E;
$lighter-black: #192C49;
$grad-1: #0767a0;
$grad-2: #21a2ee;
$inactive: #768092;
$text-grey: #758091;
$border: #D9DCEA;
$green: #27CB6B;
$red: #F92E57;
$bright-red: #FF0000;
$blue-black: #08232F;
$scrollbar-grey: #E5E7F0;
$blue: #00A0E9;
$white-blur: #00000014;
$primary-blue: #00A0E9;
$primary-black: #08232f;
$black-blue: #08232f;
$dark-grey-blue: #758091;
$medium-grey-blue: #e5e7f0;
$light-grey-blue: #f5f6fa;
$green: #27cb6b;
$red: #f92e57;
$orange: #fda700;
$soft-blue: #5357e6;
$strong-blue: #198ab5;
$magenta: #9708b8;
$required-red: #ff0000;
$white: #ffffff;
$grey: #788090;
$border-grey: #D6D6D6;


$card-box-shadow: 0px 1px 1px #0000002F;
$modal-box-shadow: 0px 2px 6px #00000034;
$input-box-shadow: 0px 1px 1px #00000029;
$dropdown-box-shadow: 0px 1px 6px #00000024;

$card-border-radius: 3px;
$modal-border-radius: 4px;
$input-border-radius: 5px;

//error vars
$error-color: #ff0000;
$error-border-color: #fb0000;

//success vars
$success-color: #1e561f;
$success-border-color: #a8c599;
$success-background-color: #fcfff5;
