/* Mixin */
@mixin vertical-align($position: relative) {
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin display-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

@mixin flex-direction-column {
  -ms-flex-direction: column;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@mixin flex-direction-row {
  -ms-flex-direction: row;
  -webkit-box-direction: row;
  -webkit-box-orient: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

@mixin three-columns {
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 40px;
}

@mixin two-columns {
  -moz-column-count: 2;
  -moz-column-gap: 5px;
  -webkit-column-count: 2;
  -webkit-column-gap: 5px;
  column-count: 2;
  column-gap: 10px;
}

@mixin space-content-evenly {
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;

  @supports ((-ms-accelerator: true) or (-ms-ime-align: auto)) {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
}

@mixin space-around-content {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

$max: 50;
$offset: 5;
$unit: 'px';

@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}

// Margins
@include list-loop('.mt-', 'margin-top');
@include list-loop('.mr-', 'margin-right');
@include list-loop('.mb-', 'margin-bottom');
@include list-loop('.ml-', 'margin-left');
@include list-loop('.m-', 'margin');

// Paddings
@include list-loop('.pt-', 'padding-top');
@include list-loop('.pr-', 'padding-right');
@include list-loop('.pb-', 'padding-bottom');
@include list-loop('.pl-', 'padding-left');
@include list-loop('.p-', 'padding');
